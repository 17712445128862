// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-deuce-who-tsx": () => import("./../../../src/pages/deuce-who.tsx" /* webpackChunkName: "component---src-pages-deuce-who-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-live-tsx": () => import("./../../../src/pages/live.tsx" /* webpackChunkName: "component---src-pages-live-tsx" */),
  "component---src-pages-mixing-submissions-tsx": () => import("./../../../src/pages/mixing-submissions.tsx" /* webpackChunkName: "component---src-pages-mixing-submissions-tsx" */),
  "component---src-pages-nuq-tsx": () => import("./../../../src/pages/nuq.tsx" /* webpackChunkName: "component---src-pages-nuq-tsx" */),
  "component---src-pages-playlists-tsx": () => import("./../../../src/pages/playlists.tsx" /* webpackChunkName: "component---src-pages-playlists-tsx" */),
  "component---src-pages-songs-tsx": () => import("./../../../src/pages/songs.tsx" /* webpackChunkName: "component---src-pages-songs-tsx" */),
  "component---src-pages-staff-tsx": () => import("./../../../src/pages/staff.tsx" /* webpackChunkName: "component---src-pages-staff-tsx" */),
  "component---src-pages-submit-2-tsx": () => import("./../../../src/pages/submit-2.tsx" /* webpackChunkName: "component---src-pages-submit-2-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */)
}

